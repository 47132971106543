/**
 *
 */
import { useSubscriptionStore } from '@/stores/SubscriptionStore'
// import { storeToRefs } from 'pinia'

const subscription = useSubscriptionStore()
// const { price } = storeToRefs(useSubscriptionStore())

export default {
  methods: {

    // Get price from ocpu
    getPrices () {
      // console.log('getPrices')
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/managcapiprice/json`, { type: 'get' })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              // subscription.price = response.data
              subscription.calls.price = true
              // console.log(response.data)
              this.dataloaded = true
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Get price from ocpu
    getSubcsPrices () {
      // console.log('getPrices')
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/subscprices/json`, { type: 'get' })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              // console.log(response.data)
              subscription.subscprice = response.data
              subscription.calls.subscprice = true
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Get subscription dates
    // is Current user is VIP client e.g. Enterprise user
    isSubscActive () {
      const leftDays = subscription.subscDayLeft
      // console.log(leftDays)
      // console.log(leftDays >= 0)
      return leftDays >= 0
      //
    },

  },
}
