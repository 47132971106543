<template>
  <v-container>
    <!-- Default orgTitle value is UDA Consulting -->
    <base-v-component
      :organization="orgTitle"
      heading="Account Settings"
      link="guide"
    />

    <v-row>
      <!-- Selected Service -->
      <v-col
        cols="12"
        lg="8"
        md="7"
        sm="12"
      >
        <v-card height="410">
          <v-card-title>
            <span class="display-2 font-weight-light mb-3 black--text">
              {{ $t('landing-compare.current-subscription') }}: {{ $t(mySubsc.title) }}
            </span>
          </v-card-title>
          <v-card-subtitle>
            <span :class="`display-1 mb-1 ${isExpired ? 'red' : 'grey'}--text`">
              Projects: {{ subscDetails.projects }}
              Forms: {{ $t(subscDetails.forms) }} ({{ $t(`${isExpired ? 'payment.expired' : 'payment.expires'}`) + ': ' }}
              <timeago :datetime="mySubsc.end" />)
            </span>
          </v-card-subtitle>
          <v-card-text>
            <v-row class="pt-5">
              <v-col>
                <v-select
                  v-model="extendType"
                  color="secondary"
                  outlined
                  item-color="secondary"
                  :items="extendTypes"
                  :label="$t('table-header.type')"
                  :item-text="$t('title')"
                  item-value="val"
                  single-line
                  dense
                  return-object
                  @change="typeSelected"
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedSubsc"
                  color="secondary"
                  outlined
                  item-color="secondary"
                  :items="pricing"
                  :label="$t('payment.subscription')"
                  :item-text="$t('headnew')"
                  item-value="code"
                  single-line
                  dense
                  :disabled="extendType.val === ''"
                  return-object
                  @change="subscSelected"
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedPaymentMode"
                  color="secondary"
                  outlined
                  item-color="secondary"
                  :items="pMode"
                  :label="$t('payment.mode')"
                  item-text="title"
                  item-value="val"
                  single-line
                  dense
                  :disabled="selectedSubsc.code === ''"
                  return-object
                  @change="priceMode"
                />
              </v-col>
              <v-col
                v-if="selectedPaymentMode.val === 1"
              >
                <v-select
                  v-model="paidMonth"
                  class="my-0"
                  color="secondary"
                  outlined
                  dense
                  required
                  :items="lessMonth"
                  :label="$t('payment.month')"
                />
              </v-col>
            </v-row>
            <v-col>
            <!-- <div>
              Details:
            </div>
            <div class="pb-0">
              Projects: 2
            </div> -->
            </v-col>
            <v-row>
              <v-col>
                <v-select
                  v-model="currencyType"
                  color="secondary"
                  outlined
                  item-color="secondary"
                  :items="currencyTypes"
                  :label="$t('payment.currency')"
                  :item-text="$t('title')"
                  item-value="val"
                  dense
                  return-object
                  :disabled="currencyLock"
                />
              </v-col>
              <v-col>
                <div
                  v-if="fullAmount.left > 0"
                  class="display-1 text-right"
                >
                  {{ `${$t('payment.price-init')} : ${currencyType.title} ${fullAmount.initial} ` }}
                </div>
                <div
                  v-if="fullAmount.left > 0"
                  class="display-1 text-right"
                >
                  {{ `${$t('payment.amount-left')} : ${fullAmount.left > 0 ? '- ' + currencyType.title + ' ' + fullAmount.left : + currencyType.title + ' ' + fullAmount.left}` }}
                </div>
                <div class="display-2 text-right">
                  {{ $t('payment.price-final') }}: {{ currencyType.title }}
                  <span class="text-h2 text-right">
                    {{ fullAmount.total }}
                  </span>
                </div>
                <div
                  v-if="selectedPaymentMode.val === 0"
                  class="display-1 text-right"
                >
                  {{ $t('payment.you-save') }}
                </div>
              </v-col>
            </v-row>
            {{ $t('payment.next-payment') }} {{ new Date(mySubsc.end).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}.
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Credit Card Details -->
      <v-col>
        <v-card height="410">
          <v-card-title>
            <span class="display-2 font-weight-light mb-3 black--text">{{ $t('payment.card-details') }}</span>
            <v-spacer />
            <v-img
              :src="require(`@/assets/cc-brands/${cc_brand}`)"
              class="mb-2"
              max-width="48"
            />
          </v-card-title>
          <v-card-text>
            <v-container>
              <!-- New Form Section -->
              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(makePayment)">
                  <v-row>
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <!-- Card number -->
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ required: true, numeric: true, min:cardnumbermin, max:cardnumbermax }"
                        :name="$t('payment.card-number')"
                      >
                        <v-text-field
                          v-model="cardDetail.number"
                          class="my-0 py-0"
                          :label="$t('payment.card-number')"
                          color="secondary"
                          outlined
                          dense
                          required
                          :error-messages="errors"
                          @input="creditCardType"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <!-- Card holder -->
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('payment.card-holder')"
                      >
                        <v-text-field
                          v-model="cardDetail.name"
                          class="my-0 py-0"
                          :label="$t('payment.card-holder')"
                          color="secondary"
                          outlined
                          dense
                          required
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col class="pt-0">
                      <!-- Expire month -->
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('payment.card-expire')"
                      >
                        <v-select
                          v-model="cardDetail.cardMonth"
                          color="secondary"
                          outlined
                          dense
                          required
                          :items="months"
                          :label="$t('payment.month')"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <span class="pr-0 mt-2">/</span>
                    <v-col class="pt-0">
                      <!-- Expire year -->
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('payment.card-expire')"
                      >
                        <v-select
                          v-model="cardDetail.cardYear"
                          color="secondary"
                          outlined
                          dense
                          required
                          :items="years"
                          item-text="year"
                          item-value="code"
                          :label="$t('payment.year')"
                          :error-messages="errors"
                          return-object
                        >
                          <template v-slot:selection="{ item }">
                            {{ item.code }}
                          </template>
                        </v-select>
                      </validation-provider>
                    </v-col>
                    <v-col class="pt-0">
                      <!-- CVC -->
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ required: true, numeric: true, min: cvcmin, max: cvcmax }"
                        :name="$t('payment.card-code')"
                      >
                        <v-text-field
                          v-model="cardDetail.cvc"
                          :label="$t('payment.card-code')"
                          color="secondary"
                          outlined
                          dense
                          required
                          counter="3"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pl-3">
                    <!-- Card holder -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Agree checkbox"
                      rules="required"
                    >
                      <v-checkbox
                        v-model="acceptTerms"
                        :error-messages="errors"
                        color="green"
                        required
                      >
                        <template v-slot:label>
                          <div>
                            I've read
                            <a
                              href="javascript:;"
                              @click.stop="privacy = true"
                            >Privacy Note</a>
                            and accept
                            <a
                              href="javascript:;"
                              @click.stop="terms = true"
                            >Term and conditions</a>
                          </div>
                        </template>
                      </v-checkbox>
                    </validation-provider>
                  </v-row>
                  <v-row>
                    <v-spacer />
                    <v-btn
                      color="success"
                      class="mr-3"
                      type="submit"
                      :disabled="fullAmount.total === 0"
                    >
                      {{ $t('payment.buy-subscription') }}
                    </v-btn>
                  </v-row>
                </form>
              </validation-observer>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- Orders -->
      <v-col>
        <v-card>
          <v-toolbar
            color="primary"
            dark
            flat
            dense
          >
            <v-icon left>
              mdi-briefcase-check
            </v-icon>
            <v-toolbar-title>{{ $t('payment.orders') }}</v-toolbar-title>
          </v-toolbar>
          <v-data-table
            :headers="subcsHeaders"
            :items="allSubscription"
            :items-per-page="5"
            class="mx-6"
          >
            <!-- Action buttons -->
            <!-- <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                small
                dark
                icon
                @click="setUserActions({ user: item, action: a.action })"
              >
                <v-icon color="primary">
                  mdi-file-download
                </v-icon>
              </v-btn>
            </template> -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- New SnackBarCard -->
    <snack-bar-card :snackbar="snackBar" />

    <!-- Privacy Note dialog -->
    <v-dialog
      v-model="privacy"
      persistent
      width="70%"
    >
      <v-card>
        <v-card-title>
          {{ $t('payment.privacy-note') }}
        </v-card-title>
        <v-card-text>
          <base-legal-privacy />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="privacy = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Terms and conditions dialog -->
    <v-dialog
      v-model="terms"
      persistent
      width="70%"
    >
      <v-card>
        <v-card-title>
          {{ $t('payment.terms-conditions') }}
        </v-card-title>
        <v-card-text>
          <base-legal-terms />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="terms = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Components
  import { extend } from 'vee-validate'
  import { regex, min, max, numeric } from 'vee-validate/dist/rules'
  import { useSubscriptionStore } from '@/stores/SubscriptionStore'
  import SnackBarCard from '@/components/SnackbarCard'
  // import axios from 'axios'
  import payment from '@/mixins/payment'

  // Mixins
  import organization from '@/mixins/organization'

  const subscription = useSubscriptionStore()

  // // Vee Validate.
  // extend('creditcard', creditcard)
  extend('regex', regex)
  extend('min', min)
  extend('max', max)
  extend('numeric', numeric)

  export default {
    name: 'Subscription',

    components: {
      SnackBarCard,
    },

    mixins: [
      payment,
      organization,
    ],

    data: () => ({
      cardDetail: {
        number: '',
        name: '',
        cardMonth: null,
        cardYear: null,
        cvc: null,
      },
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      paidMonth: 1,
      // date: null,
      activePicker: null,
      expireMenu: false,
      cc_brand: 'visa.svg',
      cardnumbermin: 13,
      cardnumbermax: 19,
      cvcmin: 0,
      cvcmax: 999,
      extendType: { title: '', val: '' },
      currencyTypes: [
        { title: 'USD', val: '840' },
        { title: 'EUR', val: '978' },
        { title: 'TRY', val: '949' },
        { title: 'RUB', val: '643' },
      ],
      currencyType: { title: '', val: '' },
      currencyLock: false,
      selectedSubsc: { code: '', heading: '' },
      // selectedPaymentMode: yearly or monthly
      selectedPaymentMode: { title: '', val: '' },
      amount: 0,
      acceptTerms: false,
      privacy: false,
      terms: false,
      // New snackbar notification
      snackBar: {
        type: 'success',
        mode: 'multi-line',
        direction: 'top center',
        timeout: 6000,
        title: '',
        text: '',
        visible: false,
      },
    }),

    // VueHTML2PDF Documentation https://www.npmjs.com/package/vue-html2pdf
    computed: {
      orgTitle () {
        if (this.$store.state.bend.organization !== undefined) {
          return this.$store.state.bend.organization.title
        } else {
          return 'UDACAPI'
        }
      },

      // // Generate years array
      years () {
        const year = new Date().getFullYear()
        const aha = Array.from({ length: 12 }, (el, index) => index + year)
        var arr = aha.map(obj => ({
          year: obj,
          code: parseInt(obj.toString().substr(2, 2)),
        }))
        return arr
      },

      mySubsc () {
        return subscription.activeSubsc
      },

      allSubscription () {
        const ms = subscription.mySubscription.map((data, i) => ({
          ...data,
          headnew: this.$t(data.title),
          active: new Date() < new Date(data.end),
        }))
        return ms.sort().reverse()
      },

      subcsHeaders () {
        return [
          { text: this.$t('payment.subscription'), value: 'headnew' },
          { text: this.$t('payment.start'), value: 'start' },
          { text: this.$t('payment.end'), value: 'end' },
          { text: this.$t('payment.price'), value: 'amount' },
          { text: this.$t('common.active'), value: 'active' },
          // { text: this.$t('table-header.actions'), value: 'actions', sortable: false, align: 'end' },
        ]
      },

      isExpired () {
        return !this.isSubscActive()
      },

      pricing () {
        let plans
        const plan = subscription.pricing.filter(x => x.code === this.mySubsc.code)
        // console.log(plan)
        // console.log(this.extendType.val)
        // assign plans (subscription) based on extend type
        if (this.extendType.val === 'upgrade') {
          plans = subscription.pricing.filter((_, i) => i > subscription.pricing.indexOf(plan[0]))
          // console.log(plans)
        // } else if (this.extendType.val === 'downgrade') {
        //   plans = subscription.pricing.filter((_, i) => i < subscription.pricing.indexOf(plan[0]))
        //   // console.log(plans)
        } else {
          plans = plan
          // console.log(plans)
        }
        // return translated names using .map()
        return plans.map((data, i) => ({
          ...data,
          headnew: this.$t(data.heading),
        }))
        // return plans
      },

      pMode () {
        return [
          { title: this.$t('landing-plans.price-yearly'), val: 0 },
          { title: this.$t('landing-plans.price-monthly'), val: 1 },
        ]
      },

      lessMonth () {
        // Generate a new array for 11 months
        const m = Array.from({ length: 11 }, (el, index) => index + 1)
        return m
      },

      extendTypes () {
        // console.log(this.mySubsc)
        const extTypes = [
          // { title: this.$t('payment.downgrade'), val: 'downgrade' },
          { title: this.$t('payment.extend'), val: 'extend' },
          { title: this.$t('payment.upgrade'), val: 'upgrade' },
        ]
        // // if subsc is already the smallest one, delete the first element ('downgrade') of the array
        // if (this.mySubsc.code === 'standard') {
        //   extTypes.shift()
        // }
        // if mySubsc is already the biggest one, delete the last element ('upgrade') of the array
        if (this.mySubsc.code === 'enterprise') {
          extTypes.pop()
        }
        return extTypes
      },

      fullAmount () {
        /**
         * Logic:
         * 1. Calculate
         */
        // console.log(this.selectedPaymentMode)
        let amountLeft = 0
        let initPrice = 0
        let finalPrice = 0
        if (this.selectedPaymentMode.val !== '') {
          // this.subscprice.standard.usd[this.paymentmode][0]
          // const price = subscription.price[subscription.pricingType][this.selectedSubsc.code]
          // console.log(price)
          const price = subscription.subscprice[this.selectedSubsc.code][this.currencyType.title.toLowerCase()][subscription.paymentmode][0]
          // console.log(subscprice)
          if (subscription.pricingType === 0) {
            initPrice = (price * 12)
          } else {
            initPrice = price * this.paidMonth
          }
        }
        // console.log(initPrice)
        // calculate avarage daily price
        if (this.extendType.val !== 'extend') {
          const total = new Date(this.mySubsc.end) - new Date(this.mySubsc.start)
          const asDays = Math.round(total / (1000 * 60 * 60 * 24))
          const dailyFee = this.mySubsc.amount / asDays
          const daysLeft = subscription.subscDayLeft
          // console.log(new Date(this.mySubsc.start))
          // console.log(asDays)
          // console.log(daysLeft)
          if (daysLeft > 0) {
            // calculate avarage left amount
            amountLeft = (Math.round((dailyFee * daysLeft)))
            // this.leftAmount(dailyFee)
            // console.log(amountLeft)
          }
        }
        // console.log(this.extendType.val)
        switch (this.extendType.val) {
          case 'upgrade':
            finalPrice = initPrice - amountLeft
            break
          case 'extend':
            finalPrice = initPrice
            break
          // case 'downgrade':
          //   // console.log('downgrade')
          //   break
        }
        if (finalPrice < 0) finalPrice = 0
        if (finalPrice === 0) amountLeft = 0
        // console.log(finalPrice.toFixed(2))
        return { initial: initPrice, left: amountLeft, total: finalPrice }
      },

      // newSubsc () {
      //   // console.log(this.mySubsc.start)
      //   return new Date()
      // },

      // computedDateFormatted () {
      //   return this.formatDate(this.date)
      // },

      subscDetails () {
        // console.log(subscription.pricing.filter(f => f.code === this.mySubsc.code))
        return subscription.pricing.filter(f => f.code === this.mySubsc.code)[0]
      },
    },

    created () {
      // get price information from ocpu
      // console.log('createdCode')
      // console.log(this.isSubscActive())

      if (!this.isSubscActive()) {
        this.snackBar = {
          type: 'error',
          mode: 'multi-line',
          title: `${this.$t('payment.subsc-expired')}`,
          text: `${this.$t('payment.buy-subsc')}`,
          visible: true,
          direction: 'centered',
          timeout: 1500,
        }
      }
      this.usedCurrency()
    },

    methods: {

      // leftAmount (dailyFee) {
      //   this.amountLeft = (Math.round((dailyFee * subscription.subscDayLeft)))
      // },

      // priceType () {
      //   subscription.pricingType = this.pricingMode
      // },

      typeSelected (item) {
        // console.log(this.extendType)
        // console.log(item)
        // console.log(this.selectedPaymentMode)
        this.selectedPaymentMode = { title: '', val: '' }
        if (item.val === 'extend') {
          this.selectedSubsc = subscription.pricing.filter(x => x.code === this.mySubsc.code)[0]
          this.currencyLock = false
        } else {
          this.selectedSubsc = { code: '', heading: '' }
          this.usedCurrency()
          this.currencyLock = true
        }
      },

      subscSelected (item) {
        // console.log(this.selectedSubsc)
        // console.log(this.selectedPaymentMode)
      },

      priceMode (item) {
        // console.log(item)
        // console.log(this.selectedPaymentMode)
        subscription.pricingType = item.val
        // console.log(subscription.pricingType)
      },

      usedCurrency () {
        const currency = this.currencyTypes.filter(x => x.val === subscription.mySubscription[0].currency)
        this.currencyType = currency[0]
      },

      async makePayment () {
        // https://stackoverflow.com/a/16714931
        // const order = (new Date()).toISOString().replace(/[^0-9]/g, "")
        const formDetails = {
          number: this.cardDetail.number.toString(),
          expiry: `${this.cardDetail.cardMonth}${this.cardDetail.cardYear.code}`,
          cvv: this.cardDetail.cvc.toString(),
          holder: this.cardDetail.name,
          // amount: this.fullAmount,
        }

        const startDate = new Date()
        // Calculate end date
        const monthAsNumber = this.selectedPaymentMode.val === 0 ? 12 : this.paidMonth
        // If there is any subsc days left, extend end date with these leftdays
        let endDate
        if (subscription.subscDayLeft > 0) {
          const myend = subscription.activeSubsc.end
          endDate = new Date(new Date(myend).setMonth(new Date(myend).getMonth() + monthAsNumber))
        } else {
          endDate = new Date(new Date().setMonth(new Date().getMonth() + monthAsNumber))
        }

        const plan = {
          code: this.selectedSubsc.code,
          start: startDate.toLocaleDateString('en-CA'),
          end: endDate.toLocaleDateString('en-CA'),
          amount: this.fullAmount.total,
          currency: this.currencyType.val,
          title: this.selectedSubsc.heading,
          orderid: (new Date()).toISOString().replace(/[^0-9]/g, ''),
          type: this.extendType.val,
        }
        // console.log(JSON.stringify(plan))

        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const formData = new FormData()
        const orgid = this.$store.state.main.orgid
        formData.append('orgid', JSON.stringify(orgid))
        formData.append('formdata', JSON.stringify(formDetails))
        formData.append('plan', JSON.stringify(plan))
        // formData.append('live', false)
        // console.log(formData)
        return new Promise((resolve, reject) => {
          this.$httpOcpu.post(`/${ocpuPack}/R/makepayment/json`, formData)
            .then((response) => {
              // console.log(response)
              const msg = response.data.message
              // console.log(msg)
              if (msg.payment[0] === 'Success') {
                this.getSubscription()
                this.snackBar = {
                  type: 'success',
                  mode: 'multi-line',
                  text: `payment: ${msg.payment[0]}, package activation: ${msg.package[0]}`,
                  visible: true,
                }
              } else {
                this.snackBar = {
                  type: 'error',
                  mode: 'multi-line',
                  text: `payment: ${msg.payment[0]}, package activation: ${msg.package[0]}`,
                  visible: true,
                }
              }
              resolve(response)
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
          // this.snackBar = {
          //   visible: true,
          // }
        })
      },

      // https://locastic.com/blog/how-to-check-credit-card-type-with-javascript
      creditCardType (cc) {
        this.creditCardImage(cc)
        const amex = new RegExp('^3[47][0-9]{13}$')
        const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
        const cup1 = new RegExp('^62[0-9]{14}[0-9]*$')
        const cup2 = new RegExp('^81[0-9]{14}[0-9]*$')

        const mastercard = new RegExp('^5[1-5][0-9]{14}$')
        const mastercard2 = new RegExp('^2[2-7][0-9]{14}$')

        const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$')
        const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$')
        const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$')

        const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$')
        const jcb = new RegExp('^35[0-9]{14}[0-9]*$')

        // console.log(cc)

        if (visa.test(cc)) {
          this.cardnumbers = 13
          return 'VISA'
        }
        if (amex.test(cc)) {
          return 'AMEX'
        }
        if (mastercard.test(cc) || mastercard2.test(cc)) {
          return 'MASTERCARD'
        }
        if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
          return 'DISCOVER'
        }
        if (diners.test(cc)) {
          return 'DINERS'
        }
        if (jcb.test(cc)) {
          return 'JCB'
        }
        if (cup1.test(cc) || cup2.test(cc)) {
          return 'CHINA_UNION_PAY'
        }
        return undefined
      },

      // return card's image
      creditCardImage (cc) {
        const amex = new RegExp('^3[47]')
        const visa = new RegExp('^4')
        const cup1 = new RegExp('^62')
        const cup2 = new RegExp('^81')

        const mastercard = new RegExp('^5[1-5]')
        const mastercard2 = new RegExp('^2[2-7]')

        const disco1 = new RegExp('^6011')
        const disco2 = new RegExp('^62')
        const disco3 = new RegExp('^6[45]')

        const diners = new RegExp('^3[0689]')
        const jcb = new RegExp('^35')

        // console.log(cc)
        let image = ''
        if (visa.test(cc)) {
          // console.log('visa')
          image = 'visa'
        } else if (amex.test(cc)) {
          image = 'american-express'
        } else if (mastercard.test(cc) || mastercard2.test(cc)) {
          image = 'mastercard'
        } else if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
          image = 'discover'
        } else if (diners.test(cc)) {
          image = 'diners'
        } else if (jcb.test(cc)) {
          image = 'jcb'
        } else if (cup1.test(cc) || cup2.test(cc)) {
          image = 'union-pay'
        } else {
          image = 'generic'
        }

        // console.log(image)
        this.cc_brand = `${image}.svg`
      },

      translateMe (val) {
        // console.log(val)
        // console.log(this.$t(val))
        return this.$t(val)
      },

    },
  }
</script>
